import {
  Grid,
  Stack,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { CustomerCardProps } from ".";
import { AddressCard } from "../Card/AddressCard";

const CustomerDetailCard: React.FC<CustomerCardProps> = ({ customer }) => {
  return (
    <Stack spacing={4}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h4" fontWeight={600}>
            顧客情報
          </Typography>
          <Chip label={`顧客ID:${customer?.id}`} size="small" />
        </Stack>
        <Divider />
        <Stack direction="row" py={0.5} mt={1}>
          <Box minWidth="180px" maxWidth="240px" flex="0 0 20%">
            <Typography variant="body2">氏名</Typography>
          </Box>
          <Box>
            <Typography variant="body2">{customer.name}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" py={0.5} mt={1}>
          <Box minWidth="180px" maxWidth="240px" flex="0 0 20%">
            <Typography variant="body2">メールアドレス</Typography>
          </Box>
          <Box>
            <Typography variant="body2">{customer.email}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" py={0.5} mt={1}>
          <Box minWidth="180px" maxWidth="240px" flex="0 0 20%">
            <Typography variant="body2">電話番号</Typography>
          </Box>
          <Box>
            <Typography variant="body2">{customer.phone}</Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h4" fontWeight={600}>
            お届け先住所
          </Typography>
        </Stack>
        <Divider />
        <Box mt={2}>
          <Grid
            container
            columnSpacing={{ xs: 1, md: 2 }}
            rowSpacing={{ xs: 1, md: 2 }}
          >
            {customer.address.map((address, index) => (
              <Grid key={`address-${index}`} item xs={12} md={6}>
                <AddressCard
                  address={address!}
                  isSelected={customer.destinationIndex === index}
                  variant="outlined"
                  elevation={0}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
};

export default CustomerDetailCard;

import {
  Box,
  Typography,
  Skeleton,
  Grid,
  Button,
  Divider,
  Stack,
  Chip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Customer } from "../API";
import { CustomerDataGrid } from "../Component/DataGrid";
import { CustomerDetailCard } from "../Component/DetailCard";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";

export const CustomerDetailPage = () => {
  const { state, dispatch } = useApplicationStore();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const urlParams = useParams<{ id: string }>();
  useEffect(() => {
    console.info(urlParams.id);
    if (state.customers === null) {
      CustomerRepository()
        .fetch()
        .then((result) =>
          dispatch({ type: ResourceOperation.FETCH_CUSTOMER, payload: result })
        );
    } else {
      setCustomer(
        state.customers.find((customer) => customer.id === urlParams.id)!
      );
    }
  }, [dispatch, state.customers]);
  return (
    <Box>
      <Typography color="inherit" variant="h3" fontWeight={600} pb={2}>
        顧客詳細
      </Typography>
      <Box pt={2}>
        {customer !== null ? (
          <CustomerDetailCard customer={customer} />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

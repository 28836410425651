import { createContext, useReducer, useMemo, useContext } from "react";
import { AppRoutes, NavigationRoutes } from "./route";
import { AppNavigationRoute } from "./types";

type PageNavigationState = {
  topLevelSlug: string;
  currentSlugRoute?: AppNavigationRoute;
  routeChildrenIndex?: number;
};

const initialState: PageNavigationState = {
  topLevelSlug: "",
  routeChildrenIndex: 0,
};

export enum PageNavigationEvent {
  onClickedHeaderNavi,
}

type PageNavigationActionType = {
  type: PageNavigationEvent.onClickedHeaderNavi;
  payload: string;
};

const ApplicationPreferenceContext = createContext(
  {} as {
    pageNavigationState: PageNavigationState;
    pageNavigationDispatcher: React.Dispatch<PageNavigationActionType>;
  }
);

const reducer = (
  pageNavigationState: PageNavigationState,
  action: PageNavigationActionType
) => {
  switch (action.type) {
    case PageNavigationEvent.onClickedHeaderNavi:
      const currentSlugs = action.payload.replace(/^\//, "").split("/");
      const parentRoute = NavigationRoutes.children?.find(
        (item) => item.slug === currentSlugs[item?.depth! - 1]
      );
      const route = parentRoute?.children?.find(
        (item) => item.path === action.payload
      )!;
      return parentRoute ? {
        topLevelSlug: route.depth === 2 ? parentRoute?.slug! : route.slug!,
        currentSlugRoute: parentRoute,
      }:{
        topLevelSlug: "orders",
        currentSlugRoute: NavigationRoutes?.children![0],
      };
    default:
      return pageNavigationState;
  }
};

type Props = {
  children?: React.ReactNode;
};

export const PageNavigationProvider: React.FC<Props> = (props) => {
  const [pageNavigationState, pageNavigationDispatcher] = useReducer(
    reducer,
    initialState
  );
  const value = useMemo(
    () => ({ pageNavigationState, pageNavigationDispatcher }),
    [pageNavigationState]
  );
  return <ApplicationPreferenceContext.Provider value={value} {...props} />;
};

export const usePageNavigation = () => {
  const context = useContext(ApplicationPreferenceContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicaitonPreference must be within a ApplicationPreferenceProvider."
    );
  }
  return context;
};

import {
  Grid,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, matchRoutes, Outlet } from "react-router-dom";
import {
  usePageNavigation,
  PageNavigationEvent,
} from "./PageNavigation/provider";
import { AppRoute, AppRoutes } from "./PageNavigation/route";
import { SideNavi } from "./PageNavigation/SideNavi/SideNavi";

export const MainContent = React.memo(() => {
  const { pageNavigationState, pageNavigationDispatcher } = usePageNavigation();
  const location = useLocation();
  const routes = matchRoutes(AppRoutes, location);
  const currentRoute =
    routes?.length! > 0
      ? (routes![0].route as AppRoute)
      : ({ path: "/" } as AppRoute);


  const defaultRoute =
    routes?.length! > 0 ? routes![0].route.path?.split("/")[1] : "/";
  useEffect(() => {
    console.log(defaultRoute)
    pageNavigationDispatcher({
      type: PageNavigationEvent.onClickedHeaderNavi,
      payload:currentRoute.parentPath ?? currentRoute.path
    });
  }, []);
  return (
    <Box>
      <Grid container py={4}>
        <Grid item xs={0} md={2.5}>
          <SideNavi currentSlugRoute={pageNavigationState.currentSlugRoute}/>
        </Grid>
        <Grid item xs={12} md={9.5}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
});

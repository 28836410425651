import {
  Box,
  Typography,
  Skeleton
} from "@mui/material";
import { useEffect } from "react";
import { OrderDataGrid } from "../Component/DataGrid";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { OrderRepository } from "../DAL/repositories/OrderRepository";

export const OrderIndexPage = () => {
  const { state, dispatch } = useApplicationStore();
  useEffect(() => {
    if (state.orders === null) {
      OrderRepository()
        .fetch()
        .then((result) =>
          dispatch({ type: ResourceOperation.FETCH_ORDER, payload: result })
        );
    }
  }, []);
  return (
    <Box>
      <Typography color="inherit" variant="h2" fontWeight={600} mb={2}>
        注文一覧
      </Typography>
      <Box>
        {state.orders !== null ? (
          <OrderDataGrid />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

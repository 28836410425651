import { useEffect, useState } from "react";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import {
  DataGrid,
  GridColDef,
} from "@mui/x-data-grid";
import { Customer } from "../../API";
import { Link, useNavigate } from "react-router-dom";

type CustomerRow = {
  id: string;
  name: string;
  phone: string;
  email: string;
};
const CustomerDataGrid = () => {
  const [lessonRows, setLessonRows] = useState<CustomerRow[]>([]);
  const { state } = useApplicationStore();
  const navigate = useNavigate()

  useEffect(() => {
    if (state.customers !== null) {
      setLessonRows(createGirdRows(state.customers));
    }
  }, [state.customers]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "氏名",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      flex: 2,
      disableColumnMenu: false,
    },
    {
      field: "phone",
      headerName: "電話番号",
      flex: 2,
      disableColumnMenu: false,
    }
  ];

  const createGirdRows = (customers: Customer[]) => {
    return customers.map((customer, idx) => ({
      id: customer.id,
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
    }));
  };

  return (
    <>
      <DataGrid
        columns={columns}
        rows={lessonRows}
        loading={state.customers === null}
        autoHeight
        density="compact"
        onRowClick={(e) => navigate(`/customer/cookie/${e.row.id}`)}
        sx={{
          "& .MuiDataGrid-cell": {
            ":focus": {
              outlineColor: "transparent",
            },
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            ":hover": {
              color: "primary.main",
            },
          },
        }}
      />
    </>
  );
};

export default CustomerDataGrid;

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import React from "react";

type Props = {
  postalCode: string;
  address: string
};
export const CustomerAddressTable = React.memo<Props>(({postalCode, address}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>郵便番号</TableCell>
            <TableCell>住所</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{postalCode}</TableCell>
            <TableCell>{address}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

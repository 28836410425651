import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Api } from "@mui/icons-material";
import { API } from "aws-amplify";
import {
  CreateCustomerInput,
  CreateCustomerMutation,
  UpdateCustomerInput,
  UpdateCustomerMutation,
  GetCustomerQuery,
  Customer,
  ListCustomersQuery,
} from "../../API";
import { createCustomer, updateCustomer } from "../../graphql/mutations";
import { getCustomer, listCustomers } from "../../graphql/queries";
export const CustomerRepository = () => {
  const fetch = () => {
    return new Promise<Customer[]>(async (res,rej) => {
      const result = (await API.graphql(
        graphqlOperation(listCustomers)
      )) as GraphQLResult<ListCustomersQuery>;
      console.log(result)
      if (result.data?.listCustomers?.items) {
        res(result.data.listCustomers.items as Customer[])
      }else {
        rej(new Error("empty"));
      }
    });
  }

  const create = (data: CreateCustomerInput) => {
    return new Promise<Customer>(async (res, rej) => {
      try {
        const result = (await API.graphql(
          graphqlOperation(createCustomer, { input: data })
        )) as GraphQLResult<CreateCustomerMutation>;
        res(result.data?.createCustomer as Customer);
      } catch (err) {
        rej(err);
      }
    });
  };
  const update = (data: UpdateCustomerInput) => {
    return new Promise<Customer>(async (res, rej) => {
      try {
        const result = (await API.graphql(
          graphqlOperation(updateCustomer, { input: data })
        )) as GraphQLResult<UpdateCustomerMutation>;
        res(result.data?.updateCustomer as Customer);
      } catch (err) {
        rej(err);
      }
    });
  };
  const get = (id: string) => {
    return new Promise<Customer>(async (res, rej) => {
      try {
        const result = (await API.graphql(
          graphqlOperation(getCustomer, { id: id })
        )) as GraphQLResult<GetCustomerQuery>;
        if(result.data?.getCustomer){
          res(result.data?.getCustomer as Customer)
        }else{
          rej()
        }
      } catch (err) {
        console.info(err)
        rej(err)
      }
    });
  };

  return {
    fetch,
    create,
    update,
    get,
  };
};

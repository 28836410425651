import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import React from "react";

type Props = {
  name: string;
  email: string;
  tel: string;
};
export const CustomerTable = React.memo<Props>(({name, email, tel}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>氏名</TableCell>
            <TableCell>メールアドレス</TableCell>
            <TableCell>電話番号</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{tel}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

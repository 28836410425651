import { useEffect, useState } from "react";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip } from "@mui/material";

import { TakeoutOrder, TakeoutOrderStatus } from "../../API";
import { useNavigate } from "react-router-dom";

type OrderRow = {
  id: string;
  status: string;
  receiptNum: string;
  customer_name?: string;
  total: string;
  date: string;
};
const TakeoutOrderDataGrid = () => {
  const [orderRows, setOrderRows] = useState<OrderRow[]>([]);
  const { state, dispatch } = useApplicationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.takeoutOrders !== null) {
      setOrderRows(createGirdRows(state.takeoutOrders));
    }
  }, [state.takeoutOrders]);

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "",
      width: 86,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Chip
          label={
            params.value === TakeoutOrderStatus.new
              ? "新規"
              : params.value === TakeoutOrderStatus.waiting
              ? "準備中"
              : params.value === TakeoutOrderStatus.invoiced
              ? "引渡済"
              : "キャンセル"
          }
          color={
            params.value === TakeoutOrderStatus.new
              ? "info"
              : params.value === TakeoutOrderStatus.waiting
              ? "warning"
              : params.value === TakeoutOrderStatus.invoiced
              ? "primary"
              : "error"
          }
          size="small"
        />
      ),
    },
    {
      field: "receiptNum",
      headerName: "受取ID",
      align: "center",
      headerAlign: "center",
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "customer_name",
      headerName: "注文者",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 2,
    },
    {
      field: "total",
      headerName: "売上",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 1,
    },
    {
      field: "date",
      headerName: "受取日",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 1,
    },
  ];

  const createGirdRows = (orders: TakeoutOrder[]) => {
    return orders.map((order, idx) => {
      const [_date, _time] = order.date?.split("T")!;
      const date = _date.split("/").map((d)=>parseInt(d));
      const time = _time.split(":").map((t)=>parseInt(t));
      return {
        id: order.id!,
        receiptNum: order.receiptNum!,
        status: order.status,
        customer_name: order.name!,
        total: `¥${order.total?.toLocaleString()}`,
        date: new Date(date[0],date[1]-1,date[2],time[0],time[1]).toLocaleDateString(),
      };
    });
  };

  return (
    <DataGrid
      columns={columns}
      rows={orderRows}
      loading={state.takeoutOrders === null}
      autoHeight
      density="compact"
      onRowClick={(e) => navigate(`/order/takeout/${e.row.id}`)}
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
      }}
    />
  );
};

export default TakeoutOrderDataGrid;

import { Box, Card, CardProps, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { Address } from "../../API";

type AddressCardProps = CardProps & {
  address: Address;
  isSelected?: boolean;
};

export const AddressCard = React.memo<AddressCardProps>(
  ({ address, isSelected, ...props }) => {
    return (
      <Card
        {...props}
        sx={{
          borderColor: isSelected ? "primary.main" : "primary.border",
          position: "relative",
        }}
      >
        {isSelected ? (
          <Chip
            label="設定されてる住所"
            variant="filled"
            color="primary"
            size="small"
            sx={{ position: "absolute", right: 8, top: 8 }}
          />
        ) : null}
        <Box p={1}>
          <Stack direction="row" py={0} mt={0}>
            <Box minWidth="120px" flex="0 0 20%">
              <Typography variant="caption" fontWeight={600}>
                郵便番号
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{address.postal_code}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" py={0} mt={0}>
            <Box minWidth="120px" flex="0 0 20%">
              <Typography variant="caption" fontWeight={600}>
                都道府県
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{address.pref}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" py={0} mt={0}>
            <Box minWidth="120px" flex="0 0 20%">
              <Typography variant="caption" fontWeight={600}>
                市区町村
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{address.city}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" py={0} mt={0}>
            <Box minWidth="120px" flex="0 0 20%">
              <Typography variant="caption" fontWeight={600}>
                町域名
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{address.town}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" py={0} mt={0}>
            <Box minWidth="120px" flex="0 0 20%">
              <Typography variant="caption" fontWeight={600}>
                丁目・番地・号
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{address.address1}</Typography>
            </Box>
          </Stack>
          {address.address2 ? (
            <Stack direction="row" py={0} mt={0}>
              <Box minWidth="120px" flex="0 0 20%">
                <Typography variant="caption" fontWeight={600}>
                  建物名・その他
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">{address.address2}</Typography>
              </Box>
            </Stack>
          ) : null}
        </Box>
      </Card>
    );
  }
);

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import React from "react";
import { StyledTableCell } from "../util";
import { IllustPlateImageRow } from "./IllustPlateImageRow";

type Props = {
  images: string[];
};

export const UploadedImageTable = React.memo<Props>(({ images }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>画像</StyledTableCell>
            <StyledTableCell align="center">ファイル名</StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {images.map((image, index) => (
            <IllustPlateImageRow
              key={`illust_plate_image_${index}`}
              image={image}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

import {
  Box,
  Typography,
  Skeleton,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TakeoutOrder} from "../API";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { OrderRepository } from "../DAL/repositories/OrderRepository";
import TakeoutOrderDetailCard from "../Component/DetailCard/TakeoutOrderDetailCard";
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

export const TakeoutOrderDetailPage = () => {
  const { state, dispatch } = useApplicationStore();
  const [order, setOrder] = useState<TakeoutOrder | null>(null);
  const { orderId } = useParams();
  const targetRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });
  useEffect(() => {
    if (state.takeoutOrders === null) {
      OrderRepository()
        .fetchTakeoutOrder()
        .then((result) =>
          dispatch({ type: ResourceOperation.FETCH_TAKEOUT_ORDER, payload: result })
        );
    } else {
      const _order = state.takeoutOrders.find((order) => order.id === orderId)!
      console.log(_order)
      setOrder(_order);
    }
  }, [dispatch, state.takeoutOrders]);
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} pb={2}>
        <Typography color="inherit" variant="h3" fontWeight={600}>
          注文詳細
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Chip label={`注文ID:${order?.id}`} size="small"/>
          <Button variant="contained" disableElevation onClick={handlePrint} startIcon={<PrintIcon/>}>印刷する</Button>
        </Stack>
      </Stack>
      <Box pt={2}>
        {order !== null ? (
          <TakeoutOrderDetailCard takeoutOrder={order} ref={targetRef} display="display" displayPrint="display"/>
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

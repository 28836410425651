import { useEffect, useState } from "react";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import { Order, OrderStatus, OrderType } from "../../API";
import { useNavigate } from "react-router-dom";

type OrderRow = {
  id: string;
  status: { type: OrderType; state: string };
  name: string;
  customer_name?: string;
  amount: string;
  total: string;
  createdAt: string;
};
const OrderDataGrid = () => {
  const [orderRows, setOrderRows] = useState<OrderRow[]>([]);
  const { state, dispatch } = useApplicationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.orders !== null) {
      setOrderRows(createGirdRows(state.orders));
    }
  }, [state.orders]);

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "",
      width: 86,
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Chip
          label={
            params.value.state === OrderStatus.NEW
              ? "新規"
              : params.value.state === OrderStatus.READY
              ? "準備中"
              : params.value.state === OrderStatus.SHIPPED
              ? params.value.type === OrderType.SHIPPING
                ? "発送済"
                : "引渡済"
              : params.value.state === OrderStatus.CANCEL
              ? "キャンセル"
              : "unknown"
          }
          color={
            params.value.state === OrderStatus.NEW
              ? "info"
              : params.value.state === OrderStatus.READY
              ? "warning"
              : params.value.state === OrderStatus.SHIPPED
              ? "primary"
              : "error"
          }
          size="small"
        />
      ),
    },
    {
      field: "name",
      headerName: "注文名",
      align: "center",
      headerAlign: "center",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "customer_name",
      headerName: "注文者",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 2,
    },
    {
      field: "amount",
      headerName: "数量",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 1,
    },
    {
      field: "total",
      headerName: "売上",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "注文日",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
      flex: 1,
    },
    /*
    {
      field: "col3",
      headerName: "確認",
      disableExport: true,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      width: 72,
      renderCell: (params) => (
        <IconButton component={Link} to={`/order/cookie/${params.id}`}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    */
  ];

  const createGirdRows = (orders: Order[]) => {
    return orders.map((order, idx) => ({
      id: order.id,
      name: order.name,
      status: { type: order.type, state: order.status },
      customer_name: order.customer?.name,
      amount: `${order.amount.toLocaleString()}個`,
      total: `¥${order.total.toLocaleString()}`,
      createdAt: new Date(order.createdAt).toLocaleDateString(),
    }));
  };

  return (
    <DataGrid
      columns={columns}
      rows={orderRows}
      loading={state.orders === null}
      autoHeight
      density="compact"
      onRowClick={(e) => navigate(`/order/cookie/${e.row.id}`)}
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
      }}
    />
  );
};

export default OrderDataGrid;

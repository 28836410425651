/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStripePaymentIntent = /* GraphQL */ `
  mutation CreateStripePaymentIntent($input: CreateStripePaymentIntentInput) {
    createStripePaymentIntent(input: $input)
  }
`;
export const cancelOrder = /* GraphQL */ `
  mutation CancelOrder($input: CancelOrderInput) {
    cancelOrder(input: $input) {
      id
      name
      amount
      image
      shippingFee
      subTotal
      total
      type
      status
      paymentStatus
      destination {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      paymentIntentId
      createdAt
      updatedAt
    }
  }
`;
export const updateTakeoutOrder = /* GraphQL */ `
  mutation UpdateTakeoutOrder(
    $restaurantId: Int
    $orderId: String
    $newStatus: TakeoutOrderStatus
  ) {
    updateTakeoutOrder(
      restaurantId: $restaurantId
      orderId: $orderId
      newStatus: $newStatus
    ) {
      restaurantId
      id
      channelId
      date
      memo
      name
      items {
        id
        name
        category
        amount
        price
        options {
          name
          amount
          price
        }
        image
      }
      paymentIntentId
      paymentWay
      phone
      receiptNum
      status
      total
      userId
      userToken
      createdAt
    }
  }
`;
export const createSales = /* GraphQL */ `
  mutation CreateSales(
    $input: CreateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    createSales(input: $input, condition: $condition) {
      id
      name
      amount
      shippingFee
      subTotal
      total
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateSales = /* GraphQL */ `
  mutation UpdateSales(
    $input: UpdateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    updateSales(input: $input, condition: $condition) {
      id
      name
      amount
      shippingFee
      subTotal
      total
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteSales = /* GraphQL */ `
  mutation DeleteSales(
    $input: DeleteSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    deleteSales(input: $input, condition: $condition) {
      id
      name
      amount
      shippingFee
      subTotal
      total
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      name
      amount
      image
      shippingFee
      subTotal
      total
      type
      status
      paymentStatus
      destination {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      paymentIntentId
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      name
      amount
      image
      shippingFee
      subTotal
      total
      type
      status
      paymentStatus
      destination {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      paymentIntentId
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      name
      amount
      image
      shippingFee
      subTotal
      total
      type
      status
      paymentStatus
      destination {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      paymentIntentId
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      lineId
      name
      email
      phone
      destinationIndex
      address {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      stripeCustomerId
      orders {
        items {
          id
          name
          amount
          image
          shippingFee
          subTotal
          total
          type
          status
          paymentStatus
          customerId
          paymentIntentId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      lineId
      name
      email
      phone
      destinationIndex
      address {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      stripeCustomerId
      orders {
        items {
          id
          name
          amount
          image
          shippingFee
          subTotal
          total
          type
          status
          paymentStatus
          customerId
          paymentIntentId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      lineId
      name
      email
      phone
      destinationIndex
      address {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      stripeCustomerId
      orders {
        items {
          id
          name
          amount
          image
          shippingFee
          subTotal
          total
          type
          status
          paymentStatus
          customerId
          paymentIntentId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import React from "react";
import { Order } from "../../../API";
import { StyledTableCell, StyledTableRow } from "../util";

type Props = {
  order: Order;
};

export const OrderTable = React.memo<Props>(({ order }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>商品名</StyledTableCell>
            <StyledTableCell align="center">数量</StyledTableCell>
            <StyledTableCell align="center">小計</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{order.name}</StyledTableCell>
            <StyledTableCell align="center">{order.amount.toLocaleString()}個</StyledTableCell>
            <StyledTableCell align="center">¥{order.subTotal?.toLocaleString()}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell rowSpan={2} colSpan={1} />
            <TableCell align="center" component="th">
              送料
            </TableCell>
            <TableCell align="center" component="th">
              ¥{order.shippingFee?.toLocaleString()}
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell align="center" component="th">
              合計
            </TableCell>
            <TableCell align="center" component="th">
              ¥{order.total.toLocaleString()}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

import { AppNavigationRoute } from "./types";
export type AppRoute = {
  path: string,
  parentPath?: string 
}
export const AppRoutes:AppRoute[] = [
  {path:"/"},
  {path:"/orders/takeout"},
  {path:"/orders/cookie"},
  {path:"/order/takeout/:orderId", parentPath:"/orders/takeout"},
  {path:"/order/cookie/:orderId", parentPath:"/orders/cookie"},
  {path:"/customers/cookie"},
  {path:"/customer/cookie/:customerId",parentPath:"/customers/cookie"},
  {path:"/sales/takeout"},
  {path:"/sales/cookie"},
]
export const NavigationRoutes: AppNavigationRoute = {
  name: "root",
  path: "/",
  depth: 0,
  children: [
    {
      name: "注文",
      path: "/orders/takeout",
      depth: 1,
      slug: "orders",
      children:[
        {
          name:"テイクアウト",
          path: "/orders/takeout",
          depth: 2,
          slug:"takeout",
          parents: ["orders"],
        },
        {
          name:"ノベルティクッキー",
          path: "/orders/cookie",
          depth: 2,
          slug:"cookie",
          parents: ["orders"],
        }
      ]
    },
    {
      name: "顧客",
      path: "/customers/cookie",
      depth: 1,
      slug: "customers",
      children:[
        {
          name:"ノベルティクッキー",
          path: "/customers/cookie",
          depth: 2,
          slug:"cookie",
          parents:["customers"],
        }
      ]
    },
    {
      name: "売上",
      path: "/sales/takeout",
      depth: 1,
      slug: "sales",
      children:[
        {
          name:"テイクアウト",
          path: "/sales/takeout",
          depth: 2,
          slug:"takeout",
          parents:["sales"]
        },
        {
          name:"ノベルティクッキー",
          path: "/sales/cookie",
          depth: 2,
          slug:"cookie",
          parents:["sales"]
        }
      ]
    },
  ],
};

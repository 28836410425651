import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { OptionItem, TakeoutOrderItem } from "../../../API";
import { StyledTableCell, StyledTableRow } from "../util";

type Props = {
  items: (TakeoutOrderItem | null)[];
  total: number;
};
type OrderOptionItem = OptionItem & {
  id: string;
};
export const TakeoutOrderItemTable = React.memo<Props>(({ items, total }) => {
  const [orderItems, setItems] =
    useState<(TakeoutOrderItem | OrderOptionItem)[]>();
  useEffect(() => {
    const _orderItems = items.filter(
      (item) => item !== null
    ) as TakeoutOrderItem[];
    const optionItems = _orderItems.flatMap((item) => {
      return (
        item.options?.map((_item) => {
          return { ..._item, __typename: "OptionItem", id: item.id };
        }) ?? []
      );
    }) as OrderOptionItem[];
    setItems([
      ..._orderItems.sort((a, b) => (a?.id! < b?.id! ? -1 : 1)),
      ...optionItems,
    ]);
  }, [items]);
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>品名</StyledTableCell>
            <StyledTableCell>数量</StyledTableCell>
            <StyledTableCell>単価</StyledTableCell>
            <StyledTableCell>合計</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderItems?.map(({ __typename, id, name, amount, price }) => (
            <StyledTableRow key={id}>
              <StyledTableCell
                align={__typename === "OptionItem" ? "right" : "left"}
              >
                {name}
              </StyledTableCell>
              <StyledTableCell>{amount!.toLocaleString()}個</StyledTableCell>
              <StyledTableCell>¥{price!.toLocaleString()}</StyledTableCell>
              <StyledTableCell>
                ¥{(price! * amount!).toLocaleString()}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <TableCell rowSpan={1} colSpan={2} />
            <TableCell align="left" component="th">
              売上合計
            </TableCell>
            <TableCell align="left" component="th">
              ¥{total.toLocaleString()}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

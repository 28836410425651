/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateStripePaymentIntentInput = {
  order: PaymentIntentOrderInput,
  customer: PaymentIntentCustomerInput,
};

export type PaymentIntentOrderInput = {
  id?: string | null,
  name: string,
  amount: number,
};

export type PaymentIntentCustomerInput = {
  name: string,
  email: string,
  phone: string,
  stripe_customer_id?: string | null,
};

export type CancelOrderInput = {
  restaurantId: number,
  orderId: string,
  paymentIntentId: string,
};

export type Order = {
  __typename: "Order",
  id: string,
  name: string,
  amount: number,
  image: string,
  shippingFee?: number | null,
  subTotal?: number | null,
  total: number,
  type: OrderType,
  status: OrderStatus,
  paymentStatus: PaymentStatus,
  destination: Address,
  customerId: string,
  customer?: Customer | null,
  paymentIntentId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum OrderType {
  TAKEOUT = "TAKEOUT",
  SHIPPING = "SHIPPING",
}


export enum OrderStatus {
  NEW = "NEW",
  READY = "READY",
  SHIPPED = "SHIPPED",
  CANCEL = "CANCEL",
}


export enum PaymentStatus {
  PENDING = "PENDING",
  INVOICED = "INVOICED",
  CANCEL = "CANCEL",
}


export type Address = {
  __typename: "Address",
  postal_code: string,
  pref: string,
  city: string,
  town: string,
  address1?: string | null,
  address2?: string | null,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  lineId: string,
  name: string,
  email: string,
  phone: string,
  destinationIndex?: number | null,
  address:  Array<Address | null >,
  stripeCustomerId?: string | null,
  orders?: ModelOrderConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export enum TakeoutOrderStatus {
  new = "new",
  waiting = "waiting",
  invoiced = "invoiced",
  cancel = "cancel",
}


export type TakeoutOrder = {
  __typename: "TakeoutOrder",
  restaurantId?: number | null,
  id?: string | null,
  channelId?: string | null,
  date?: string | null,
  memo?: string | null,
  name?: string | null,
  items?:  Array<TakeoutOrderItem | null > | null,
  paymentIntentId?: string | null,
  paymentWay?: string | null,
  phone?: string | null,
  receiptNum?: string | null,
  status: TakeoutOrderStatus,
  total?: number | null,
  userId?: string | null,
  userToken?: string | null,
  createdAt?: string | null,
};

export type TakeoutOrderItem = {
  __typename: "TakeoutOrderItem",
  id?: string | null,
  name: string,
  category?: string | null,
  amount: number,
  price: number,
  options?:  Array<OptionItem | null > | null,
  image?: string | null,
};

export type OptionItem = {
  __typename: "OptionItem",
  name?: string | null,
  amount?: number | null,
  price?: number | null,
};

export type CreateSalesInput = {
  id?: string | null,
  name?: string | null,
  amount?: number | null,
  shippingFee?: number | null,
  subTotal?: number | null,
  total: number,
  customerId: string,
  date: string,
};

export type ModelSalesConditionInput = {
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  shippingFee?: ModelIntInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelSalesConditionInput | null > | null,
  or?: Array< ModelSalesConditionInput | null > | null,
  not?: ModelSalesConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Sales = {
  __typename: "Sales",
  id: string,
  name?: string | null,
  amount?: number | null,
  shippingFee?: number | null,
  subTotal?: number | null,
  total: number,
  customerId: string,
  customer?: Customer | null,
  date: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSalesInput = {
  id: string,
  name?: string | null,
  amount?: number | null,
  shippingFee?: number | null,
  subTotal?: number | null,
  total?: number | null,
  customerId?: string | null,
  date?: string | null,
};

export type DeleteSalesInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  name: string,
  amount: number,
  image: string,
  shippingFee?: number | null,
  subTotal?: number | null,
  total: number,
  type: OrderType,
  status: OrderStatus,
  paymentStatus: PaymentStatus,
  destination: AddressInput,
  customerId: string,
  paymentIntentId?: string | null,
  createdAt?: string | null,
};

export type AddressInput = {
  postal_code: string,
  pref: string,
  city: string,
  town: string,
  address1?: string | null,
  address2?: string | null,
};

export type ModelOrderConditionInput = {
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  image?: ModelStringInput | null,
  shippingFee?: ModelIntInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  type?: ModelOrderTypeInput | null,
  status?: ModelOrderStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  customerId?: ModelIDInput | null,
  paymentIntentId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type ModelOrderTypeInput = {
  eq?: OrderType | null,
  ne?: OrderType | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type UpdateOrderInput = {
  id: string,
  name?: string | null,
  amount?: number | null,
  image?: string | null,
  shippingFee?: number | null,
  subTotal?: number | null,
  total?: number | null,
  type?: OrderType | null,
  status?: OrderStatus | null,
  paymentStatus?: PaymentStatus | null,
  destination?: AddressInput | null,
  customerId?: string | null,
  paymentIntentId?: string | null,
  createdAt?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreateCustomerInput = {
  id?: string | null,
  lineId: string,
  name: string,
  email: string,
  phone: string,
  destinationIndex?: number | null,
  address: Array< AddressInput | null >,
  stripeCustomerId?: string | null,
};

export type ModelCustomerConditionInput = {
  lineId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  destinationIndex?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerInput = {
  id: string,
  lineId?: string | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  destinationIndex?: number | null,
  address?: Array< AddressInput | null > | null,
  stripeCustomerId?: string | null,
};

export type DeleteCustomerInput = {
  id: string,
};

export type Shop = {
  __typename: "Shop",
  id: number,
  name?: string | null,
  address?: string | null,
  tel?: string | null,
  state: boolean,
  dayOff?: Array< string | null > | null,
  products?:  Array<Product | null > | null,
  categoris?:  Array<Category | null > | null,
  options?:  Array<Option | null > | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  name: string,
  nameEn?: string | null,
  price: number,
  image: string,
  hasOption: boolean,
  hasSetOption: boolean,
  categoryId: number,
  description?: string | null,
  options?: string | null,
  setmenuOption?: string | null,
};

export type Category = {
  __typename: "Category",
  id?: number | null,
  name: string,
};

export type Option = {
  __typename: "Option",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  type?: OptionType | null,
  isRequired?: boolean | null,
  selectionType: SelectionType,
  hasLimit: boolean,
  limit?: number | null,
  isGoods?: boolean | null,
  items?:  Array<OptionItem | null > | null,
};

export enum OptionType {
  Single = "Single",
  Set = "Set",
}


export enum SelectionType {
  Radio = "Radio",
  Checkbox = "Checkbox",
}


export type GetTakeoutOrdersResult = {
  __typename: "GetTakeoutOrdersResult",
  items?:  Array<TakeoutOrder | null > | null,
  nextToken?: string | null,
};

export type GetTakeoutSalesResult = {
  __typename: "GetTakeoutSalesResult",
  items?:  Array<TakeoutSales | null > | null,
  nextToken?: string | null,
};

export type TakeoutSales = {
  __typename: "TakeoutSales",
  restaurantId?: number | null,
  id?: string | null,
  amount?: number | null,
  customer?: string | null,
  memo?: string | null,
  name?: string | null,
  price?: number | null,
  orderId?: string | null,
  receiptDate?: string | null,
  receiptNum?: string | null,
  subTotal?: number | null,
  orderedAt?: string | null,
};

export type ModelSalesFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  shippingFee?: ModelIntInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  customerId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelSalesFilterInput | null > | null,
  or?: Array< ModelSalesFilterInput | null > | null,
  not?: ModelSalesFilterInput | null,
};

export type ModelSalesConnection = {
  __typename: "ModelSalesConnection",
  items:  Array<Sales | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  image?: ModelStringInput | null,
  shippingFee?: ModelIntInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  type?: ModelOrderTypeInput | null,
  status?: ModelOrderStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  customerId?: ModelIDInput | null,
  paymentIntentId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  lineId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  destinationIndex?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type CreateStripePaymentIntentMutationVariables = {
  input?: CreateStripePaymentIntentInput | null,
};

export type CreateStripePaymentIntentMutation = {
  createStripePaymentIntent?: string | null,
};

export type CancelOrderMutationVariables = {
  input?: CancelOrderInput | null,
};

export type CancelOrderMutation = {
  cancelOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTakeoutOrderMutationVariables = {
  restaurantId?: number | null,
  orderId?: string | null,
  newStatus?: TakeoutOrderStatus | null,
};

export type UpdateTakeoutOrderMutation = {
  updateTakeoutOrder?:  {
    __typename: "TakeoutOrder",
    restaurantId?: number | null,
    id?: string | null,
    channelId?: string | null,
    date?: string | null,
    memo?: string | null,
    name?: string | null,
    items?:  Array< {
      __typename: "TakeoutOrderItem",
      id?: string | null,
      name: string,
      category?: string | null,
      amount: number,
      price: number,
      options?:  Array< {
        __typename: "OptionItem",
        name?: string | null,
        amount?: number | null,
        price?: number | null,
      } | null > | null,
      image?: string | null,
    } | null > | null,
    paymentIntentId?: string | null,
    paymentWay?: string | null,
    phone?: string | null,
    receiptNum?: string | null,
    status: TakeoutOrderStatus,
    total?: number | null,
    userId?: string | null,
    userToken?: string | null,
    createdAt?: string | null,
  } | null,
};

export type CreateSalesMutationVariables = {
  input: CreateSalesInput,
  condition?: ModelSalesConditionInput | null,
};

export type CreateSalesMutation = {
  createSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSalesMutationVariables = {
  input: UpdateSalesInput,
  condition?: ModelSalesConditionInput | null,
};

export type UpdateSalesMutation = {
  updateSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSalesMutationVariables = {
  input: DeleteSalesInput,
  condition?: ModelSalesConditionInput | null,
};

export type DeleteSalesMutation = {
  deleteSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetShopInfoQueryVariables = {
  id: number,
};

export type GetShopInfoQuery = {
  getShopInfo?:  {
    __typename: "Shop",
    id: number,
    name?: string | null,
    address?: string | null,
    tel?: string | null,
    state: boolean,
    dayOff?: Array< string | null > | null,
    products?:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameEn?: string | null,
      price: number,
      image: string,
      hasOption: boolean,
      hasSetOption: boolean,
      categoryId: number,
      description?: string | null,
      options?: string | null,
      setmenuOption?: string | null,
    } | null > | null,
    categoris?:  Array< {
      __typename: "Category",
      id?: number | null,
      name: string,
    } | null > | null,
    options?:  Array< {
      __typename: "Option",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      type?: OptionType | null,
      isRequired?: boolean | null,
      selectionType: SelectionType,
      hasLimit: boolean,
      limit?: number | null,
      isGoods?: boolean | null,
      items?:  Array< {
        __typename: "OptionItem",
        name?: string | null,
        amount?: number | null,
        price?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetTakeoutOrdersQueryVariables = {
  restaurantId: number,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTakeoutOrdersQuery = {
  getTakeoutOrders?:  {
    __typename: "GetTakeoutOrdersResult",
    items?:  Array< {
      __typename: "TakeoutOrder",
      restaurantId?: number | null,
      id?: string | null,
      channelId?: string | null,
      date?: string | null,
      memo?: string | null,
      name?: string | null,
      items?:  Array< {
        __typename: "TakeoutOrderItem",
        id?: string | null,
        name: string,
        category?: string | null,
        amount: number,
        price: number,
        image?: string | null,
      } | null > | null,
      paymentIntentId?: string | null,
      paymentWay?: string | null,
      phone?: string | null,
      receiptNum?: string | null,
      status: TakeoutOrderStatus,
      total?: number | null,
      userId?: string | null,
      userToken?: string | null,
      createdAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTakeoutSalesQueryVariables = {
  restaurantId: number,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTakeoutSalesQuery = {
  getTakeoutSales?:  {
    __typename: "GetTakeoutSalesResult",
    items?:  Array< {
      __typename: "TakeoutSales",
      restaurantId?: number | null,
      id?: string | null,
      amount?: number | null,
      customer?: string | null,
      memo?: string | null,
      name?: string | null,
      price?: number | null,
      orderId?: string | null,
      receiptDate?: string | null,
      receiptNum?: string | null,
      subTotal?: number | null,
      orderedAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSalesQueryVariables = {
  id: string,
};

export type GetSalesQuery = {
  getSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSalesQueryVariables = {
  filter?: ModelSalesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSalesQuery = {
  listSales?:  {
    __typename: "ModelSalesConnection",
    items:  Array< {
      __typename: "Sales",
      id: string,
      name?: string | null,
      amount?: number | null,
      shippingFee?: number | null,
      subTotal?: number | null,
      total: number,
      customerId: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        lineId: string,
        name: string,
        email: string,
        phone: string,
        destinationIndex?: number | null,
        stripeCustomerId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSalesByCreatedDateQueryVariables = {
  date: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSalesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSalesByCreatedDateQuery = {
  getSalesByCreatedDate?:  {
    __typename: "ModelSalesConnection",
    items:  Array< {
      __typename: "Sales",
      id: string,
      name?: string | null,
      amount?: number | null,
      shippingFee?: number | null,
      subTotal?: number | null,
      total: number,
      customerId: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        lineId: string,
        name: string,
        email: string,
        phone: string,
        destinationIndex?: number | null,
        stripeCustomerId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      name: string,
      amount: number,
      image: string,
      shippingFee?: number | null,
      subTotal?: number | null,
      total: number,
      type: OrderType,
      status: OrderStatus,
      paymentStatus: PaymentStatus,
      destination:  {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      },
      customerId: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        lineId: string,
        name: string,
        email: string,
        phone: string,
        destinationIndex?: number | null,
        stripeCustomerId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      paymentIntentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderByCustomerQueryVariables = {
  customerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetOrderByCustomerQuery = {
  getOrderByCustomer?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      name: string,
      amount: number,
      image: string,
      shippingFee?: number | null,
      subTotal?: number | null,
      total: number,
      type: OrderType,
      status: OrderStatus,
      paymentStatus: PaymentStatus,
      destination:  {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      },
      customerId: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        lineId: string,
        name: string,
        email: string,
        phone: string,
        destinationIndex?: number | null,
        stripeCustomerId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      paymentIntentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderByCreatedDateQueryVariables = {
  createdAt: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetOrderByCreatedDateQuery = {
  getOrderByCreatedDate?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      name: string,
      amount: number,
      image: string,
      shippingFee?: number | null,
      subTotal?: number | null,
      total: number,
      type: OrderType,
      status: OrderStatus,
      paymentStatus: PaymentStatus,
      destination:  {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      },
      customerId: string,
      customer?:  {
        __typename: "Customer",
        id: string,
        lineId: string,
        name: string,
        email: string,
        phone: string,
        destinationIndex?: number | null,
        stripeCustomerId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      paymentIntentId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomerByLineIdQueryVariables = {
  lineId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomerByLineIdQuery = {
  customerByLineId?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateSalesSubscription = {
  onCreateSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSalesSubscription = {
  onUpdateSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSalesSubscription = {
  onDeleteSales?:  {
    __typename: "Sales",
    id: string,
    name?: string | null,
    amount?: number | null,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    name: string,
    amount: number,
    image: string,
    shippingFee?: number | null,
    subTotal?: number | null,
    total: number,
    type: OrderType,
    status: OrderStatus,
    paymentStatus: PaymentStatus,
    destination:  {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    },
    customerId: string,
    customer?:  {
      __typename: "Customer",
      id: string,
      lineId: string,
      name: string,
      email: string,
      phone: string,
      destinationIndex?: number | null,
      address:  Array< {
        __typename: "Address",
        postal_code: string,
        pref: string,
        city: string,
        town: string,
        address1?: string | null,
        address2?: string | null,
      } | null >,
      stripeCustomerId?: string | null,
      orders?:  {
        __typename: "ModelOrderConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    paymentIntentId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    lineId: string,
    name: string,
    email: string,
    phone: string,
    destinationIndex?: number | null,
    address:  Array< {
      __typename: "Address",
      postal_code: string,
      pref: string,
      city: string,
      town: string,
      address1?: string | null,
      address2?: string | null,
    } | null >,
    stripeCustomerId?: string | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      items:  Array< {
        __typename: "Order",
        id: string,
        name: string,
        amount: number,
        image: string,
        shippingFee?: number | null,
        subTotal?: number | null,
        total: number,
        type: OrderType,
        status: OrderStatus,
        paymentStatus: PaymentStatus,
        customerId: string,
        paymentIntentId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import {
  PreferenceEvent,
  useApplicationPreference,
} from "../../provider/ApplicationPreferenceProvider";
import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { HeaderNavi } from "./PageNavigation/HeaderNavi/HeaderNavi";
import { NavigationRoutes } from "./PageNavigation/route";

export const Header: React.FC = React.memo(() => {
  const { preferenceDispatcher } = useApplicationPreference();
  const navigate = useNavigate();
  const defaultMode = localStorage.getItem("themeMode") ?? "light";
  const [isLightMode, setLightMode] = useState<boolean>(
    defaultMode === "light" ? true : false
  );
  const modeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLightMode(event.target.checked);
    preferenceDispatcher({
      type: PreferenceEvent.SET_MODE,
      payload: event.target.checked ? "light" : "dark",
    });
  };
  return (
    <Box>
      <Box component="header" sx={{ bgcolor: grey[100] }}>
        <Stack
          direction="row"
          alignItems="center"
          px={2}
          pt={1}
          pb={0.7}
          width="92.5%"
          maxWidth={1690}
          mx="auto"
        >
          <Box flexGrow={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              Les Cinq Epices
            </Typography>
          </Box>
          <Box>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                Auth.signOut().then(() => {
                  navigate("/")
                });
              }}
            >
              ログアウト
            </Button>
          </Box>
          {/*
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <ThemeModeSwitch onChange={modeHandler} checked={isLightMode} />
          </Box>
          */}
        </Stack>
      </Box>
      <Divider />
      <Box width="92.5%" maxWidth={1690} mx="auto">
        <HeaderNavi navigationRoute={NavigationRoutes} />
      </Box>
      <Divider />
    </Box>
  );
});

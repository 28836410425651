import {
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import { useEffect } from "react";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { OrderRepository } from "../DAL/repositories/OrderRepository";
import TakeoutOrderDataGrid from "../Component/DataGrid/TakeoutOrderDataGrid";

export const TakeoutOrderIndexPage = () => {
  const { state, dispatch } = useApplicationStore();
  useEffect(() => {
    if (state.takeoutOrders === null) {
      OrderRepository().fetchTakeoutOrder().then((result)=>dispatch({
        type: ResourceOperation.FETCH_TAKEOUT_ORDER, payload: result
      }));
    }
  }, []);
  return (
    <Box>
      <Typography color="inherit" variant="h2" fontWeight={600} mb={2}>
        注文一覧
      </Typography>
      <Box>
        {state.takeoutOrders !== null ? (
          <TakeoutOrderDataGrid />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export const Footer: React.FC = React.memo(() => {
  return (
    <Box component="footer" sx={{ bgcolor: grey[200] }} py={1}>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        Les Cinq Epices {new Date().getFullYear()}.
      </Typography>
    </Box>
  );
});

import { createContext, useContext, useMemo, useReducer } from "react";
import { Order, Customer, TakeoutOrder, TakeoutSales, Sales } from "../API";

export type ApplicationStore = {
  orders: Order[] | null;
  takeoutOrders: TakeoutOrder[] | null;
  customers: Customer[] | null;
  sales: Sales[] | null;
  takeoutSales: TakeoutSales[] | null;
};

export enum ResourceOperation {
  FETCH_ORDER,
  FETCH_TAKEOUT_ORDER,
  FETCH_CUSTOMER,
  FETCH_SALES,
  FETCH_TAKEOUT_SALES,
  GET_ORDER,
  GET_CUSTOMER,
  UPDATE_ORDER,
  UPDATE_TAKEOUT_ORDER,
  UPDATE_CUSTOMER,
  DELETE_ORDER,
  DELETE_CUSTOMER,
}

export type ACTIONTYPE =
  | { type: ResourceOperation.FETCH_ORDER; payload: Order[] }
  | { type: ResourceOperation.FETCH_TAKEOUT_ORDER; payload: TakeoutOrder[] }
  | { type: ResourceOperation.FETCH_CUSTOMER; payload: Customer[] }
  | { type: ResourceOperation.FETCH_SALES; payload: Sales[] }
  | { type: ResourceOperation.FETCH_TAKEOUT_SALES; payload: TakeoutSales[] }
  | { type: ResourceOperation.GET_ORDER; payload: Order }
  | { type: ResourceOperation.GET_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.UPDATE_ORDER; payload: Order }
  | { type: ResourceOperation.UPDATE_TAKEOUT_ORDER; payload: TakeoutOrder }
  | { type: ResourceOperation.UPDATE_CUSTOMER; payload: Customer }
  | { type: ResourceOperation.DELETE_ORDER; payload: Order }
  | { type: ResourceOperation.DELETE_CUSTOMER; payload: Customer };

export const ApplicationStoreContext = createContext(
  {} as {
    state: ApplicationStore;
    dispatch: React.Dispatch<ACTIONTYPE>;
  }
);

const reducer = (state: ApplicationStore, action: ACTIONTYPE) => {
  switch (action.type) {
    case ResourceOperation.FETCH_ORDER:
      return { ...state, orders: action.payload.sort((a,b)=>a.createdAt>b.createdAt?-1:1) };
    case ResourceOperation.FETCH_TAKEOUT_ORDER:
      return { ...state, takeoutOrders: action.payload.sort((a,b)=>a.date!>b.date!?-1:1)};
    case ResourceOperation.FETCH_CUSTOMER:
      return {
        ...state,
        customers: action.payload.filter((cstmr) => cstmr.name !== ""),
      };
    case ResourceOperation.FETCH_SALES:
      return { ...state, sales: action.payload.sort((a,b)=>a.createdAt>b.createdAt?-1:1) };
    case ResourceOperation.FETCH_TAKEOUT_SALES:
      return { ...state, takeoutSales: action.payload.sort((a,b)=>a.orderedAt!>b.orderedAt!?-1:1)};
    case ResourceOperation.UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders!.map((order) =>
          order.id !== action.payload.id ? order : action.payload
        ),
      };
    case ResourceOperation.UPDATE_TAKEOUT_ORDER:
      return {
        ...state,
        takeoutOrders: state.takeoutOrders!.map((order) =>
          order.id !== action.payload.id ? order : action.payload
        ),
      };
    case ResourceOperation.UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers!.map((cstmr) =>
          cstmr.id !== action.payload.id ? cstmr : action.payload
        ),
      };

    case ResourceOperation.DELETE_ORDER:
      return {
        ...state,
        orders: state.orders!.filter((order) => order.id !== action.payload.id),
      };
    case ResourceOperation.DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers!.filter(
          (customer) => customer.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

const initialStore: ApplicationStore = {
  customers: null,
  orders: null,
  takeoutOrders: null,
  sales: null,
  takeoutSales: null,
};

type Props = {
  children?: React.ReactNode;
};

export const ApplicationStoreProvider: React.FC<Props> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialStore);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return <ApplicationStoreContext.Provider value={value} {...props} />;
};

export const useApplicationStore = () => {
  const context = useContext(ApplicationStoreContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicationStore must be within a ApplicationStoreProvider."
    );
  }
  return context;
};

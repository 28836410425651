import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

type CancelComfirmationDialogProps = DialogProps & {
  titile?: string;
  description?: string;
  cancelHandler?: (event?: React.MouseEvent<HTMLElement>) => void;
  comfirmHandler?: (event?: React.MouseEvent<HTMLElement>) => void;
};
export const CancelComfirmationDialog: React.FC<
  CancelComfirmationDialogProps
> = ({ title, description, cancelHandler, comfirmHandler, ...props }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  return (
    <Dialog
      {...props}
      onClose={(e) => {
        setLoading(false);
        cancelHandler!();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            setLoading(false);
            cancelHandler!(e);
          }}
          variant="outlined"
          color="primary"
        >
          戻る
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={(e) => {
            setLoading(true);
            comfirmHandler!(e);
          }}
          variant="contained"
          color="error"
          disableElevation
        >
          キャンセルする
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

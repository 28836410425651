import {
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import { useEffect } from "react";
import { CustomerDataGrid } from "../Component/DataGrid";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";

export const CustomerIndexPage = () => {
  const { state, dispatch } = useApplicationStore();
  useEffect(() => {
    CustomerRepository()
      .fetch()
      .then((result) =>
        dispatch({ type: ResourceOperation.FETCH_CUSTOMER, payload: result })
      );
  }, [dispatch]);
  return (
    <Box>
      <Typography color="inherit" variant="h2" fontWeight={600} mb={2}>
        顧客一覧
      </Typography>
      <Box>
        {state.customers !== null ? (
          <CustomerDataGrid />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

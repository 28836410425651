import { Box, Typography, Skeleton } from "@mui/material";
import { useEffect } from "react";
import SalesDataGrid from "../Component/DataGrid/SalesDataGrid";
import { SalesRepository } from "../DAL/repositories/SalesRepository";
import { ResourceOperation, useApplicationStore } from "../provider/ApplicationStoreProvider";

export const SalesPage = () => {
  const { state, dispatch } = useApplicationStore();
  useEffect(() => {
    if (state.sales === null) {
      SalesRepository().fetch().then((result)=>{
        dispatch({
          type: ResourceOperation.FETCH_SALES,
          payload: result
        })
      });
    }
  }, []);
  return (
    <Box>
      <Typography color="inherit" variant="h2" fontWeight={600} mb={2}>
        売上
      </Typography>
      <Box>
        {state.sales !== null ? (
          <SalesDataGrid />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

import { useEffect, useState } from "react";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TakeoutSales } from "../../API";
import { useNavigate } from "react-router-dom";

type SalesRow = {
  date: string;
  name: string;
  amount: string;
  option?: string | null;
  total: string;
};
const TakeoutSalesDataGrid = () => {
  const [orderRows, setOrderRows] = useState<SalesRow[]>([]);
  const { state, dispatch } = useApplicationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.takeoutSales !== null) {
      setOrderRows(createGirdRows(state.takeoutSales));
    }
  }, [state.takeoutSales]);

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "日付",
      width: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "商品名",
      align: "right",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "amount",
      headerName: "数量",
      align: "right",
      width: 64,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "option",
      headerName: "オプション",
      align: "right",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "total",
      headerName: "売上",
      align: "right",
      headerAlign: "center",
      disableColumnMenu: false,
    },
  ];

  const createGirdRows = (sales: TakeoutSales[]) => {
    return sales.map((item, idx) => ({
      id: idx,
      date: new Date(item.orderedAt!).toLocaleDateString(),
      name: item.name!,
      option: item.memo,
      amount: `${item.amount}個`,
      total: `¥${item.subTotal!.toLocaleString()}`,
    }));
  };

  return (
    <DataGrid
      columns={columns}
      rows={orderRows}
      loading={state.takeoutSales === null}
      autoHeight
      density="compact"
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
      }}
    />
  );
};

export default TakeoutSalesDataGrid;

import styled from "@emotion/styled";
import {
  TableCell,
  TableRow,
  tableCellClasses,
} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "primary.main",
    color: "primary.main",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //@ts-ignore
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td": {
    border: 0,
  },
}));

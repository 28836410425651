import { ThemeProvider } from "@emotion/react";
import { Box, CssBaseline } from "@mui/material";
import ModalProvider from "mui-modal-provider";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationPreference } from "../../provider/ApplicationPreferenceProvider";
import { BaseTheme } from "./BaseTheme";
import { Footer } from "./Footter";
import { Header } from "./Header";
import { MainContent } from "./MainContent";
import { PageNavigationProvider } from "./PageNavigation/provider";

export const SimpleAdmin = React.memo(() => {
  const { preference } = useApplicationPreference();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/orders/takeout");
  }, []);
  return (
    <ThemeProvider theme={BaseTheme(preference.mode)}>
      <ModalProvider>
        <PageNavigationProvider>
          <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <CssBaseline />
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Header />
              <Box
                component="main"
                height="100%"
                width="92.5%"
                maxWidth={1690}
                mx="auto"
                px={1}
              >
                <MainContent />
              </Box>
              <Footer />
            </Box>
          </Box>
        </PageNavigationProvider>
      </ModalProvider>
    </ThemeProvider>
  );
});

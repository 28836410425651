import {
  Grid,
  Stack,
  Typography,
  Button,
  Divider,
  MenuItem,
  Link,
  Chip,
  styled,
  MenuProps,
  Menu,
} from "@mui/material";
import Box from "@mui/material/Box";
import { OrderCardProps } from ".";
import { Image } from "@aws-amplify/ui-react";
import { OrderStatus, OrderType } from "../../API";
import { forwardRef, useEffect, useState } from "react";
import { getImageFromS3 } from "../../util/Storage";
import { LoadingButton } from "@mui/lab";
import { OrderRepository } from "../../DAL/repositories/OrderRepository";
import {
  ResourceOperation,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";
import { useModal } from "mui-modal-provider";
import { CancelComfirmationDialog } from "../Dialog/CancelComfirmationDialog";
import { CustomerTable } from "../Table/CustomerTable/CustomerTable";
import { CustomerAddressTable } from "../Table/CustomerTable/CustomerAddressTable";
import { OrderTable } from "../Table/Order/OrderTable";
import { UploadedImageTable } from "../Table/UploadedImageTable/UploadedImageTable";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: 0,
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: 0,
    },
  },
}));

const OrderDetailCard = forwardRef<HTMLDivElement, OrderCardProps>(
  ({ order }, ref) => {
    const [imageSrc, setImageSrc] = useState<string>();

    const { dispatch } = useApplicationStore();
    const outputFullAddress = () => {
      return (
        order.destination.pref +
        order.destination.city +
        order.destination.town +
        order.destination.address1 +
        " " +
        order.destination.address2
      );
    };
    const [isLoading, setLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setLoading(true);
      const { status } = event.currentTarget.dataset;

      OrderRepository()
        .updateStatus(order, status as OrderStatus)
        .then((order) => {
          dispatch({
            type: ResourceOperation.UPDATE_ORDER,
            payload: order,
          });
          setAnchorEl(null);
          setLoading(false);
        });
    };
    const handleClose = () => {
      setAnchorEl(null);
      setLoading(false);
    };

    const { showModal } = useModal();
    const cancelHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      const modal = showModal(CancelComfirmationDialog, {
        title: "キャンセルの確認",
        description:
          "キャンセルの通知はされません。お客様にキャンセルの連絡を入れてから変更してください。",
        cancelHandler: (e) => {
          modal.hide();
          setAnchorEl(null);
          setLoading(false);
        },
        comfirmHandler: (e) => {
          setLoading(true);
          OrderRepository()
            .updateStatus(order, OrderStatus.CANCEL)
            .then((order) => {
              dispatch({
                type: ResourceOperation.UPDATE_ORDER,
                payload: order,
              });
              modal.hide();
              setAnchorEl(null);
              setLoading(false);
            });
        },
      });
    };
    useEffect(() => {
      getImageFromS3(order.image).then((result) => setImageSrc(result));
    }, []);
    return (
      <Stack
        spacing={4}
        ref={ref}
        sx={{
          "@media print": {
            pageBreakAfter: "always",
            width: "172mm",
            height: "251mm",
            p: "10mm",
            boxSizing: "border-box",
            "& .MuiButton-root": {
              display: "none",
            },
          },
        }}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="h3" fontWeight={600}>
                  注文ステータス
                </Typography>
                <Chip
                  label={
                    order.status === OrderStatus.NEW
                      ? "新規"
                      : order.status === OrderStatus.READY
                      ? "準備中"
                      : order.status === OrderStatus.SHIPPED
                      ? "発送済"
                      : order.status === OrderStatus.CANCEL
                      ? "キャンセル"
                      : "unknown"
                  }
                  color={
                    order.status === OrderStatus.NEW
                      ? "info"
                      : order.status === OrderStatus.READY
                      ? "warning"
                      : order.status === OrderStatus.SHIPPED
                      ? "primary"
                      : "error"
                  }
                />
              </Stack>
            </Box>
            <Box>
              <LoadingButton
                variant="contained"
                disableElevation
                loading={isLoading}
                disabled={order.status === OrderStatus.CANCEL}
                onClick={handleClick}
              >
                変更する
              </LoadingButton>
              <StyledMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
                {[
                  {
                    label: "新規",
                    status: OrderStatus.NEW,
                    handler: handleMenuClose,
                  },
                  {
                    label: "準備中",
                    status: OrderStatus.READY,
                    handler: handleMenuClose,
                  },
                  {
                    label: order.type === OrderType.SHIPPING ? "発送済":"引渡済",
                    status: OrderStatus.SHIPPED,
                    handler: handleMenuClose,
                  },
                  {
                    label: "キャンセル",
                    status: OrderStatus.CANCEL,
                    handler: cancelHandler,
                  },
                ]
                  .filter((item) => item.status !== order.status)
                  .map((item) => (
                    <MenuItem
                      key={`order-status-${item.status}`}
                      data-status={item.status}
                      onClick={item.handler}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
              </StyledMenu>
            </Box>
          </Stack>
          <Divider />
        </Box>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h3" fontWeight={600}>
              注文情報
            </Typography>
            <Typography variant="caption" color="text">
              注文日時：{new Date(order.createdAt).toLocaleString()}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row" py={0.5} mt={1}>
            <OrderTable order={order} />
          </Stack>
        </Box>
        <Box>
          <Typography variant="h3" fontWeight={600} mb={1}>
            印刷画像
          </Typography>
          <Divider />
          <UploadedImageTable images={[order.image]} />
        </Box>
        <Box>
          <Typography variant="h3" fontWeight={600} mb={1}>
            注文者情報
          </Typography>
          <Divider />
          <Box mt={1}>
            <CustomerTable
              name={order.customer?.name!}
              email={order.customer?.email!}
              tel={order.customer?.email!}
            />
          </Box>
        </Box>
        {order.type === OrderType.SHIPPING ? (
          <Box>
            <Typography variant="h3" fontWeight={600} mb={1}>
              お届け先情報
            </Typography>
            <Divider />
            <Box mt={1}>
              <CustomerAddressTable
                postalCode={order.destination.postal_code}
                address={outputFullAddress()}
              />
            </Box>
          </Box>
        ) : null}
      </Stack>
    );
  }
);

export default OrderDetailCard;

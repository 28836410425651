import { Amplify, I18n } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { Authenticator} from "@aws-amplify/ui-react";
import { SnackbarProvider } from "notistack";
import "@aws-amplify/ui-react/styles.css";
import { translations } from "@aws-amplify/ui-react";
import { ApplicationStoreProvider } from "./provider/ApplicationStoreProvider";
import PrivateApp from "./PrivateApp";
import { ApplicationPreferenceProvider } from "./provider/ApplicationPreferenceProvider";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

I18n.putVocabularies({
  ja: {
    Name: "貴社名",
    "Send code": "コードを送信",
    "Signing in": "ログイン中",
  },
});

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_WEB_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
});

const App = () => {
  return (
    <Authenticator.Provider>
      <ApplicationStoreProvider>
        <ApplicationPreferenceProvider>
          <BrowserRouter>
            <SnackbarProvider maxSnack={4}>
              <PrivateApp />
            </SnackbarProvider>
          </BrowserRouter>
        </ApplicationPreferenceProvider>
      </ApplicationStoreProvider>
    </Authenticator.Provider>
  );
};

export default App;

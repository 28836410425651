import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";
import {
  CancelOrderInput,
  CancelOrderMutation,
  GetTakeoutOrdersQuery,
  ListOrdersQuery,
  OrderStatus,
  TakeoutOrder,
  TakeoutOrderStatus,
  UpdateOrderInput,
  UpdateOrderMutation,
  UpdateTakeoutOrderMutation,
} from "../../API";
import { Order } from "../../API";
import { cancelOrder,updateOrder, updateTakeoutOrder} from "../../graphql/mutations";
import { getTakeoutOrders, listOrders } from "../../graphql/queries";

export const OrderRepository = () => {
  const fetch = () => {
    return new Promise<Order[]>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(listOrders,{filter:{
          paymentStatus:{
            ne: "PENDING"
          }
        }})
      )) as GraphQLResult<ListOrdersQuery>;

      if (result.data?.listOrders?.items) {
        res(result.data.listOrders.items as Order[]);
      } else {
        res([])
      }
    });
  };
  const fetchTakeoutOrder = () => {
    return new Promise<TakeoutOrder[]>(async (res) => {
      const result = (await API.graphql(
        graphqlOperation(getTakeoutOrders, {
          restaurantId: 1012,
        })
      )) as GraphQLResult<GetTakeoutOrdersQuery>;
     
      if (result.data?.getTakeoutOrders?.items?.length! > 0) {
        console.log(result.data?.getTakeoutOrders?.items)
        res(result.data?.getTakeoutOrders?.items as TakeoutOrder[]);
      } else {
        res([])
      }
    });
  };

  const updateStatus = (order: Order, newStatus: OrderStatus) => {
    return new Promise<Order>(async (res, rej) => {
      if(newStatus !== OrderStatus.CANCEL) {
        const updateInput: UpdateOrderInput = {
          id: order.id,
          status: newStatus,
        };
        const result = (await API.graphql(
          graphqlOperation(updateOrder, { input: updateInput })
        )) as GraphQLResult<UpdateOrderMutation>;

        if(result.errors){
          rej(result.errors)
        }else{
          res(result.data?.updateOrder as Order)
        }
      }else{
        const input: CancelOrderInput = {
          restaurantId: 1012,
          orderId: order.id,
          paymentIntentId: order.paymentIntentId!
        }
        const result = (await API.graphql(
          graphqlOperation(cancelOrder, { input: input })
        )) as GraphQLResult<CancelOrderMutation>;
        if(result.errors){
          rej(result.errors)
        }else{
          res(result.data?.cancelOrder as Order)
        }
      }
    });
  };

  const updateTakeoutOrderStatus = (
    orderId: String,
    newStatus: TakeoutOrderStatus
  ) => {
    return new Promise<TakeoutOrder>(async (res, rej) => {
      
      const result = await API.graphql(
        graphqlOperation(updateTakeoutOrder, {
          restaurantId: 1012,
          orderId: orderId,
          newStatus: newStatus,
        })
      ) as GraphQLResult<UpdateTakeoutOrderMutation>
      if(result.errors){
        rej(result.errors)
      }else{
        res(result.data?.updateTakeoutOrder as TakeoutOrder)
      }
    });
  };


  return {
    fetch,
    fetchTakeoutOrder,
    updateStatus,
    updateTakeoutOrderStatus
  };
};

import { Box, List, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { AppRoute, AppRoutes } from "../route";
import { AppNavigationRoute } from "../types";
import { SideNaviItem } from "./SideNaviItem";

type Props = {
  currentSlugRoute?: AppNavigationRoute
}
export const SideNavi = React.memo<Props>(({currentSlugRoute}) => {

  const location = useLocation();
  const routes = matchRoutes(AppRoutes, location);
  const currentRoute =
    routes?.length! > 0
      ? (routes![0].route as AppRoute)
      : ({ path: "/" } as AppRoute);

  return currentSlugRoute ? (
    <Box>
      <Typography variant="h4" fontWeight={600}>
        {currentSlugRoute?.name}
      </Typography>
      <List sx={{ borderLeft: 1, borderLeftColor: grey[200], p: 0, mt: 2 }}>
        {currentSlugRoute?.children?.map((route, index) => (
          <SideNaviItem
            key={`${route.slug}-path-${index}`}
            label={route.name!}
            path={route.path}
            isActive={
              route.path === currentRoute.path ||
              route.path === currentRoute.parentPath
            }
          >
            {route.children?.map((subRoute, index) => (
              <SideNaviItem
                key={`${subRoute.slug}-path-${index}`}
                label={subRoute.name!}
                path={subRoute.path}
                isActive={
                  subRoute.path === currentRoute.path ||
                  subRoute.path === currentRoute.parentPath
                }
              />
            ))}
          </SideNaviItem>
        ))}
      </List>
    </Box>
  ) : null;
});

import { useEffect, useState } from "react";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import {
  DataGrid,
  GridColDef
} from "@mui/x-data-grid";

import { Sales} from "../../API";
import { useNavigate } from "react-router-dom";

type SalesRow = {
  date: string;
  name: string;
  amount: string;
  shippingFee: string;
  total: string;
};
const SalesDataGrid = () => {
  const [orderRows, setOrderRows] = useState<SalesRow[]>([]);
  const { state, dispatch } = useApplicationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.sales !== null) {
      setOrderRows(createGirdRows(state.sales));
    }
  }, [state.sales]);

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "日付",
      align: "center",
      headerAlign: "center",
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "商品名",
      align: "right",
      flex: 2,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "amount",
      headerName: "数量",
      align: "right",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "shippingFee",
      headerName: "送料",
      align: "right",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: false,
    },
    {
      field: "total",
      headerName: "売上",
      align: "right",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: false,
    },
  ];

  const createGirdRows = (sales: Sales[]) => {
    return sales.map((item, idx) => ({
      id: idx,
      date: new Date(item.date!).toLocaleDateString(),
      name: "ノベルティクッキー",
      amount: `${item.amount?.toLocaleString()}個`,
      shippingFee: `¥${item.shippingFee?.toLocaleString()}`,
      total: `¥${item.total?.toLocaleString()}`,
    }));
  };

  return (
    <DataGrid
      columns={columns}
      rows={orderRows}
      loading={state.sales === null}
      autoHeight
      density="compact"
      sx={{
        "& .MuiDataGrid-cell": {
          ":focus": {
            outlineColor: "transparent",
          },
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
          ":hover": {
            color: "primary.main",
          },
        },
      }}
    />
  );
};

export default SalesDataGrid;

import { Box, Typography, Skeleton, Button, Stack, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Order, OrderStatus, OrderType } from "../API";
import { OrderDataGrid } from "../Component/DataGrid";
import { OrderDetailCard } from "../Component/DetailCard";
import {
  ResourceOperation,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { OrderRepository } from "../DAL/repositories/OrderRepository";
import { useReactToPrint } from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';

export const OrderDetailPage = () => {
  const { state, dispatch } = useApplicationStore();
  const [order, setOrder] = useState<Order | null>(null);
  const { orderId } = useParams();
  const targetRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });
  useEffect(() => {
    if (state.orders === null) {
      OrderRepository()
        .fetch()
        .then((result) =>
          dispatch({ type: ResourceOperation.FETCH_ORDER, payload: result })
        );
    } else {
      const _order = state.orders.find((order) => order.id === orderId)!;
      setOrder(_order);
    }
  }, [dispatch, state.orders]);
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2} pb={2}>
        <Typography color="inherit" variant="h3" fontWeight={600}>
          注文詳細
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Chip
              label={order?.type === OrderType.SHIPPING ? "配送" : "店頭受取"}
              color={order?.type === OrderType.SHIPPING ? "primary" : "success"}
            />
            <Chip label={`注文ID:${order?.id}`} size="small" />
          </Stack>
          <Button
            variant="contained"
            disableElevation
            onClick={handlePrint}
            startIcon={<PrintIcon />}
          >
            印刷する
          </Button>
        </Stack>
      </Stack>
      <Box pt={2}>
        {order !== null ? (
          <OrderDetailCard
            classes="print-container"
            order={order}
            ref={targetRef}
            display="display"
            displayPrint="display"
          />
        ) : (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Box>
    </Box>
  );
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShopInfo = /* GraphQL */ `
  query GetShopInfo($id: Int!) {
    getShopInfo(id: $id) {
      id
      name
      address
      tel
      state
      dayOff
      products {
        id
        name
        nameEn
        price
        image
        hasOption
        hasSetOption
        categoryId
        description
        options
        setmenuOption
      }
      categoris {
        id
        name
      }
      options {
        id
        name
        description
        type
        isRequired
        selectionType
        hasLimit
        limit
        isGoods
        items {
          name
          amount
          price
        }
      }
    }
  }
`;
export const getTakeoutOrders = /* GraphQL */ `
  query GetTakeoutOrders($restaurantId: Int!, $limit: Int, $nextToken: String) {
    getTakeoutOrders(
      restaurantId: $restaurantId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        restaurantId
        id
        channelId
        date
        memo
        name
        items {
          id
          name
          category
          amount
          price
          image
          options{
            name
            amount
            price
          }
        }
        paymentIntentId
        paymentWay
        phone
        receiptNum
        status
        total
        userId
        userToken
        createdAt
      }
      nextToken
    }
  }
`;
export const getTakeoutSales = /* GraphQL */ `
  query GetTakeoutSales($restaurantId: Int!, $limit: Int, $nextToken: String) {
    getTakeoutSales(
      restaurantId: $restaurantId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        restaurantId
        id
        amount
        customer
        memo
        name
        price
        orderId
        receiptDate
        receiptNum
        subTotal
        orderedAt
      }
      nextToken
    }
  }
`;
export const getSales = /* GraphQL */ `
  query GetSales($id: ID!) {
    getSales(id: $id) {
      id
      name
      amount
      shippingFee
      subTotal
      total
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $filter: ModelSalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        shippingFee
        subTotal
        total
        customerId
        customer {
          id
          lineId
          name
          email
          phone
          destinationIndex
          stripeCustomerId
          createdAt
          updatedAt
        }
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSalesByCreatedDate = /* GraphQL */ `
  query GetSalesByCreatedDate(
    $date: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelSalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSalesByCreatedDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        amount
        shippingFee
        subTotal
        total
        customerId
        customer {
          id
          lineId
          name
          email
          phone
          destinationIndex
          stripeCustomerId
          createdAt
          updatedAt
        }
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      name
      amount
      image
      shippingFee
      subTotal
      total
      type
      status
      paymentStatus
      destination {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      customerId
      customer {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      paymentIntentId
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        image
        shippingFee
        subTotal
        total
        type
        status
        paymentStatus
        destination {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        customerId
        customer {
          id
          lineId
          name
          email
          phone
          destinationIndex
          stripeCustomerId
          createdAt
          updatedAt
        }
        paymentIntentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderByCustomer = /* GraphQL */ `
  query GetOrderByCustomer(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByCustomer(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        amount
        image
        shippingFee
        subTotal
        total
        type
        status
        paymentStatus
        destination {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        customerId
        customer {
          id
          lineId
          name
          email
          phone
          destinationIndex
          stripeCustomerId
          createdAt
          updatedAt
        }
        paymentIntentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderByCreatedDate = /* GraphQL */ `
  query GetOrderByCreatedDate(
    $createdAt: AWSDateTime!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByCreatedDate(
      createdAt: $createdAt
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        amount
        image
        shippingFee
        subTotal
        total
        type
        status
        paymentStatus
        destination {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        customerId
        customer {
          id
          lineId
          name
          email
          phone
          destinationIndex
          stripeCustomerId
          createdAt
          updatedAt
        }
        paymentIntentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      lineId
      name
      email
      phone
      destinationIndex
      address {
        postal_code
        pref
        city
        town
        address1
        address2
      }
      stripeCustomerId
      orders {
        items {
          id
          name
          amount
          image
          shippingFee
          subTotal
          total
          type
          status
          paymentStatus
          customerId
          paymentIntentId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerByLineId = /* GraphQL */ `
  query CustomerByLineId(
    $lineId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByLineId(
      lineId: $lineId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineId
        name
        email
        phone
        destinationIndex
        address {
          postal_code
          pref
          city
          town
          address1
          address2
        }
        stripeCustomerId
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

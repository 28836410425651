import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";
import { GetTakeoutSalesQuery, GetTakeoutSalesResult, ListSalesQuery, Sales, TakeoutSales } from "../../API";
import { getTakeoutSales, listSales } from "../../graphql/queries";

export const SalesRepository = () => {
  const fetch = () => {
    return new Promise<Sales[]>(async (res, rej) => {
      const result = (await API.graphql(
        graphqlOperation(listSales)
      )) as GraphQLResult<ListSalesQuery>;
      res(result.data?.listSales?.items as Sales[])
    });
  };

  const fetchTakeoutSales = () => {
    return new Promise<TakeoutSales[]>(async (res, rej) => {
      const result = await API.graphql(
        graphqlOperation(getTakeoutSales, { restaurantId: 1012, limit: 1000 })
      ) as GraphQLResult<GetTakeoutSalesQuery>;
      console.log(result)
      res(result.data?.getTakeoutSales?.items as TakeoutSales[])
    });
  };

  return { fetch, fetchTakeoutSales };
};

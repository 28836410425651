import React, { useEffect, useState } from "react";
import { Image } from "@aws-amplify/ui-react";
import { Button, IconButton, Link, Skeleton, TableCell } from "@mui/material";
import { getImageFromS3 } from "../../../util/Storage";
import { StyledTableRow } from "../util";
import DownloadIcon from "@mui/icons-material/Download";

type Props = {
  image: string;
};
export const IllustPlateImageRow = React.memo<Props>(({ image }) => {
  const [imageSrc, setSource] = useState<string>();

  useEffect(() => {
    getImageFromS3(image).then((result) => setSource(result));
  }, [image]);
  return imageSrc ? (
    <StyledTableRow>
      <TableCell>
        <Image alt={imageSrc} src={imageSrc} maxWidth={80} />
      </TableCell>

      <TableCell>{image}</TableCell>

      <TableCell>
        <IconButton component={Link} href={imageSrc} download>
          <DownloadIcon />
        </IconButton>
      </TableCell>
    </StyledTableRow>
  ) : null;
});

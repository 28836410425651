import {
  MenuItem,
  Stack,
  Typography,
  Divider,
  Chip,
  Menu,
  MenuProps,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import { TakeoutOrderCardProps } from ".";
import { forwardRef, memo, useEffect, useState } from "react";
import { TakeoutOrderItemCard } from "../Card/TakeoutOrderItemCard";
import { LoadingButton } from "@mui/lab";
import { TakeoutOrderStatus } from "../../API";
import { OrderRepository } from "../../DAL/repositories/OrderRepository";
import {
  ResourceOperation,
  useApplicationStore,
} from "../../provider/ApplicationStoreProvider";
import styled from "@emotion/styled";
import { useModal } from "mui-modal-provider";
import { CancelComfirmationDialog } from "../Dialog/CancelComfirmationDialog";
import { IllustPlateImage } from "../Image/IllustPlateImage";
import { TakeoutOrderItemTable } from "../Table/TakeoutOrder/TakeoutOrderItemTable";
import { TakeoutCustomerTable } from "../Table/CustomerTable/TakeoutCustomerTable";
import { UploadedImageTable } from "../Table/UploadedImageTable/UploadedImageTable";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: 0,
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: 0,
    },
  },
}));

const TakeoutOrderDetailCard = forwardRef<
  HTMLDivElement,
  TakeoutOrderCardProps
>(({ takeoutOrder }, ref) => {
  const [imageSrc, setImageSrc] = useState<string[] | null>();
  const [receiptDate, setReceiptDate] = useState<Date>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoading(true);
    const { status } = event.currentTarget.dataset;
    OrderRepository()
      .updateTakeoutOrderStatus(takeoutOrder?.id!, status as TakeoutOrderStatus)
      .then((order) => {
        dispatch({
          type: ResourceOperation.UPDATE_TAKEOUT_ORDER,
          payload: order,
        });
        setAnchorEl(null);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setLoading(false);
  };
  const { showModal } = useModal();
  const cancelHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const modal = showModal(CancelComfirmationDialog, {
      title: "キャンセルの確認",
      description:
        "キャンセルの通知はされません。お客様にキャンセルの連絡を入れてから変更してください。",
      cancelHandler: (e) => {
        modal.hide();
        setAnchorEl(null);
        setLoading(false);
      },
      comfirmHandler: (e) => {
        setLoading(true);
        OrderRepository()
          .updateTakeoutOrderStatus(
            takeoutOrder?.id!,
            TakeoutOrderStatus.cancel
          )
          .then((order) => {
            dispatch({
              type: ResourceOperation.UPDATE_TAKEOUT_ORDER,
              payload: order,
            });
            modal.hide();
            setAnchorEl(null);
            setLoading(false);
          });
      },
    });
  };
  const { dispatch } = useApplicationStore();

  useEffect(() => {
    if (takeoutOrder?.date) {
      const [_date, _time] = takeoutOrder.date?.split("T")!;
      const date = _date.split("/").map((d) => parseInt(d));
      const time = _time.split(":").map((t) => parseInt(t));
      setReceiptDate(new Date(date[0], date[1] - 1, date[2], time[0], time[1]));
    }
    const images = takeoutOrder.items?.filter((order) =>
      order?.image ? true : false
    );
    if (images?.length! > 0) {
      setImageSrc(images?.map((order) => order?.image!));
    }
  }, [takeoutOrder.date, takeoutOrder.items]);
  return (
    <Stack
      spacing={4}
      ref={ref}
      sx={{
        "@media print": {
          pageBreakAfter: "always",
          width: "172mm",
          height: "251mm",
          p: "10mm",
          boxSizing: "border-box",
          "& .MuiButton-root": {
            display: "none",
          },
        },
      }}
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mb={1}
        >
          <Typography variant="h3" fontWeight={600}>
            {`受取日時：${receiptDate?.toLocaleString()}`}
          </Typography>
          <Chip label={`受取ID：${takeoutOrder.receiptNum}`} size="medium" />
        </Stack>
      </Box>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h3" fontWeight={600}>
                注文ステータス
              </Typography>
              <Chip
                label={
                  takeoutOrder.status === TakeoutOrderStatus.new
                    ? "新規"
                    : takeoutOrder.status === TakeoutOrderStatus.waiting
                    ? "準備中"
                    : takeoutOrder.status === TakeoutOrderStatus.invoiced
                    ? "引渡済"
                    : "キャンセル"
                }
                color={
                  takeoutOrder.status === TakeoutOrderStatus.new
                    ? "info"
                    : takeoutOrder.status === TakeoutOrderStatus.waiting
                    ? "warning"
                    : takeoutOrder.status === TakeoutOrderStatus.invoiced
                    ? "primary"
                    : "error"
                }
              />
            </Stack>
          </Box>
          <Box>
            <LoadingButton
              variant="contained"
              disableElevation
              loading={isLoading}
              onClick={handleClick}
            >
              変更する
            </LoadingButton>
            <StyledMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
              {[
                {
                  label: "新規",
                  status: TakeoutOrderStatus.new,
                  handler: handleMenuClose,
                },
                {
                  label: "準備中",
                  status: TakeoutOrderStatus.waiting,
                  handler: handleMenuClose,
                },
                {
                  label: "引渡済",
                  status: TakeoutOrderStatus.invoiced,
                  handler: handleMenuClose,
                },
                {
                  label: "キャンセル",
                  status: TakeoutOrderStatus.cancel,
                  handler: cancelHandler,
                },
              ]
                .filter((item) => item.status !== takeoutOrder.status)
                .map((item) => (
                  <MenuItem
                    key={`takeout-order-status-${item.status}`}
                    data-status={item.status}
                    onClick={item.handler}
                  >
                    {item.label}
                  </MenuItem>
                ))}
            </StyledMenu>
          </Box>
        </Stack>
        <Divider />
      </Box>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h3" fontWeight={600}>
            注文内容
          </Typography>
          <Typography variant="caption" color="text">
            注文日時：{new Date(takeoutOrder.createdAt!).toLocaleString()}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          py={0.5}
          mt={1}
          justifyContent="space-between"
          alignItems="baseline"
          flexWrap="wrap"
        >
          {takeoutOrder.items?.length! > 0 ? (
            <TakeoutOrderItemTable
              items={takeoutOrder.items!}
              total={takeoutOrder.total!}
            />
          ) : null}
        </Stack>
      </Box>
      {imageSrc?.length! > 0 ? (
        <Box>
          <Typography variant="h3" fontWeight={600} mb={1}>
            イラストプレート画像
          </Typography>
          <Divider />
          <Stack direction="row">
            {imageSrc ? <UploadedImageTable images={imageSrc} /> : null}
          </Stack>
        </Box>
      ) : null}
      <Box>
        <Typography variant="h3" fontWeight={600} mb={1}>
          注文者情報
        </Typography>
        <Divider />
        <Box mt={1}>
          <TakeoutCustomerTable
            name={takeoutOrder.name!}
            tel={takeoutOrder.phone!}
          />
        </Box>
      </Box>
      {takeoutOrder.memo ? (
        <Box>
          <Typography variant="h3" fontWeight={600} mb={1}>
            備考
          </Typography>
          <Paper variant="outlined">
            <Box p={1}>{takeoutOrder.memo}</Box>
          </Paper>
        </Box>
      ) : null}
    </Stack>
  );
});

export default TakeoutOrderDetailCard;

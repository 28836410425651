import { withAuthenticator } from "@aws-amplify/ui-react";
import { Routes, Route } from "react-router-dom";
import { CustomerDetailPage } from "./pages/CustomerDetailPage";
import { CustomerIndexPage } from "./pages/CustomerIndexPage";
import { OrderDetailPage } from "./pages/OrderDetailPage";
import { OrderIndexPage } from "./pages/OrderIndexPage";
import { SalesPage } from "./pages/SalesPage";
import { TakeoutOrderDetailPage } from "./pages/TakeoutOrderDetailPage";
import { TakeoutOrderIndexPage } from "./pages/TakeoutOrderIndexPage";
import { TakeoutSalesPage } from "./pages/TakeoutSalesPage";
import { SimpleAdmin } from "./themes/simple_admin/SimpleAdmin";

const PrivateApp = () => {
  return (
    <Routes>
      <Route path="/" element={<SimpleAdmin />}>
        <Route path="/orders/takeout" element={<TakeoutOrderIndexPage />} />
        <Route path="/orders/cookie" element={<OrderIndexPage />} />
        <Route path="/order/takeout/:orderId" element={<TakeoutOrderDetailPage />} />
        <Route path="/order/cookie/:orderId" element={<OrderDetailPage />} />
        <Route path="/customers/cookie" element={<CustomerIndexPage />} />
        <Route path="/customer/cookie/:id" element={<CustomerDetailPage />} />
        <Route path="/sales/takeout" element={<TakeoutSalesPage />} />
        <Route path="/sales/cookie" element={<SalesPage />} />
      </Route>
    </Routes>
  );
};

export default withAuthenticator(PrivateApp, {
  initialState: "signIn",
  hideSignUp: true,
});
